import { FeatureFlagStore, LaunchDarklyFeatureFlags } from '@awork/_shared/state/feature-flag.store'
import { Injectable, Signal, computed, effect, inject } from '@angular/core'
import { LDClient, LDContext, LDOptions, initialize } from 'launchdarkly-js-client-sdk'

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { Observable } from 'rxjs'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { environment } from '@awork/environments/environment'

interface FlagUpdates {
  string: { current: boolean; previous: boolean }
}

const CLIENT_ID = environment === 'production' ? '664b275445554710265392f1' : '6645dad0469b8f1226e08d7e'

/**
 * Service to integrate feature flags with LaunchDarkly
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagIntegrationService {
  private workspaceQuery = inject(WorkspaceQuery)
  private featureFlagStore = inject(FeatureFlagStore)

  private client: LDClient

  private workspace = this.workspaceQuery.queryCurrentWorkspace()
  private workspaceContext: Signal<LDContext> = computed(() => this.getWorkspaceContext(), {
    equal: this.isContextUpdated
  })

  private flags: LaunchDarklyFeatureFlags

  private _flagsFetched$ = new BehaviorSubject(false)

  constructor() {
    effect(() => this.initializeWorkspaceFeatureFlags())
  }

  /**
   * Initializes the workspace feature flags
   * @withSignal {Signal<LDContext>} workspaceContext
   */
  private initializeWorkspaceFeatureFlags(): void {
    if (!this.workspaceContext()) {
      return
    }

    this.client?.close()
    const context: LDContext = this.workspaceContext()

    const options: LDOptions = {
      streaming: true,
      logger: {
        error: console.error,
        warn: () => {},
        info: () => {},
        debug: () => {}
      }
    }

    this.client = initialize(CLIENT_ID, context, options)

    // Update the store with the initial flag values
    this.client.on('ready', () => {
      const flags = this.client.allFlags() as LaunchDarklyFeatureFlags
      this.featureFlagStore.updateFlags(flags)
      this.flags = flags
      this._flagsFetched$.next(true)
    })

    // Update the store with flag changes (streaming)
    this.client.on('change', (flagUpdates: FlagUpdates) => {
      const flags = Object.keys(flagUpdates).reduce((flags, key) => {
        flags[key] = flagUpdates[key].current
        return flags
      }, {} as LaunchDarklyFeatureFlags)

      this.featureFlagStore.updateFlags(flags)
      this.flags = { ...this.flags, ...flags }
    })
  }

  /**
   * Gets all the feature flags set in LaunchDarkly
   * @returns {LaunchDarklyFeatureFlags}
   */
  getAllFlags(): LaunchDarklyFeatureFlags {
    return this.flags
  }

  /**
   * Gets the observable for the flags fetched state
   * @returns {Observable<boolean>}
   */
  get flagsFetched$(): Observable<boolean> {
    return this._flagsFetched$.asObservable()
  }

  /**
   * Gets the workspace context
   * @withSignal {Signal<Workspace>} workspace
   * @returns {LDContext}
   */
  private getWorkspaceContext(): LDContext {
    return this.workspace()
      ? {
          kind: 'workspace',
          key: this.workspace().id,
          name: this.workspace().name
        }
      : undefined
  }

  /**
   * Checks whether the context is updated
   * @param {LDContext} oldContext
   * @param {LDContext} newContext
   * @returns {boolean}
   */
  private isContextUpdated(oldContext: LDContext, newContext: LDContext): boolean {
    return !!oldContext && !!newContext && oldContext?.key === newContext?.key
  }
}
